import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import logo from './logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import * as EmailValidator from 'email-validator';

import { Form, Container, Col, Row, Button, ProgressBar } from 'react-bootstrap';





class Contact extends React.Component {

  constructor(props) {
    super(props)
    this.isCustomer= this.isCustomer.bind(this);
    this.isNotCustomer= this.isNotCustomer.bind(this)
    this.state = {
      data:null,
      isCustomer: 1,
    }
  }


  componentDidMount() {
    if(this.props.formData.isCustomer === 0){
      this.setState({isCustomer: 1});
    }else{
      this.setState({isCustomer: 0});
    }
  }
  isCustomer(){
    this.setState({isCustomer: 0});
    this.props.formData.isCustomer = 1;
  }

  isNotCustomer(){
    this.setState({isCustomer: 1});
    this.props.formData.isCustomer = 0;
  }

  render(){

    const renderNextButton = () => {

      if ((this.props.formData.firstName !== "" && this.props.formData.lastName !== "" && this.props.formData.address !== "" && this.props.formData.city !== "" && this.props.formData.email !== "" && this.props.formData.zip !== "" && this.props.formData.tel !== "" && this.props.formData.isCustomer === 0 && EmailValidator.validate(this.props.formData.email) === true) || (this.props.formData.isCustomer === 1 && this.props.formData.customerNumber.length === 6 && this.props.formData.tel !== "" && EmailValidator.validate(this.props.formData.email) === true)) {
        return <Button className="btn_right" onClick={this.props.navigation.next}>Weiter</Button>;
      } else {
        return <Button className="btn_right" onClick={this.props.navigation.next} disabled>Weiter</Button>;
      }
    }

    return (

      <div className="form">
      <ProgressBar now={65} label="4 von 7"/>
        <img style={{marginTop:40,marginBottom:40}} src={logo} alt="Logo" />
        <div style={{marginTop:10,marginBottom:40}}>

        </div>
        <Container>

        <div style={this.state.isCustomer ? {} : { display: 'none' }}>
        <Button onClick={this.isCustomer}>Ich bin bereits Kunde</Button>
        <br/><br/>
        <Row>
        <Col>
        <Form>
        <Form.Row>
          <Form.Group style={{textAlign:'left'}} as={Col} controlId="formGridCity">
            <Form.Label>Vorname*:</Form.Label>
            <Form.Control name="firstName" style={{textAlign:'left'}} value={this.props.formData.firstName} onChange={this.props.setForm} />
          </Form.Group>
          <Form.Group style={{textAlign:'left'}} as={Col} controlId="formGridZip">
            <Form.Label>Nachname*:</Form.Label>
            <Form.Control name="lastName" style={{textAlign:'left'}} value={this.props.formData.lastName} onChange={this.props.setForm}/>
          </Form.Group>
        </Form.Row>
        <div style={{height:40}}></div>
          <Form.Group style={{textAlign:'left'}} controlId="formGridAddress1">
            <Form.Label>Strasse / Hausnummer*:</Form.Label>
            <Form.Control name="address" placeholder="" style={{textAlign:'left'}} value={this.props.formData.address} onChange={this.props.setForm}/>
          </Form.Group>
          <Form.Row>
            <Form.Group style={{textAlign:'left'}} as={Col} controlId="formGridCity">
              <Form.Label>Stadt*:</Form.Label>
              <Form.Control name="city" style={{textAlign:'left'}} value={this.props.formData.city} onChange={this.props.setForm}/>
            </Form.Group>
            <Form.Group style={{textAlign:'left'}} as={Col} controlId="formGridZip">
              <Form.Label>PLZ*:</Form.Label>
              <Form.Control name="zip" style={{textAlign:'left'}} value={this.props.formData.zip} onChange={this.props.setForm}/>
            </Form.Group>
          </Form.Row>
          <div style={{height:40}}></div>
          <Form.Group style={{textAlign:'left'}} controlId="formBasicEmail">
            <Form.Label>Email Adresse*:</Form.Label>
            <Form.Control name="email" style={{textAlign:'left'}} type="email" placeholder="" value={this.props.formData.email} onChange={this.props.setForm}/>
          </Form.Group>
          <Form.Group style={{textAlign:'left'}} controlId="formBasicEmail">
            <Form.Label>Telefon*:</Form.Label>
            <Form.Control name="tel" style={{textAlign:'left'}} type="phone" placeholder="" value={this.props.formData.tel} onChange={this.props.setForm}/>
          </Form.Group>

          <Form.Group style={{textAlign:'left'}} controlId="exampleForm.ControlTextarea1">
            <Form.Label>Zusatzinformationen</Form.Label>
            <Form.Control name="extraContact" style={{textAlign:'left'}} as="textarea" rows={3} value={this.props.formData.extraContact} onChange={this.props.setForm}/>
            <div className="div_text_left"><p style={{color:'#eb4f34'}}>* Pflichtfeld</p></div>
          </Form.Group>


        </Form>
        </Col>
        </Row>

        </div>
        <div>
        <div style={this.state.isCustomer ? {display: 'none'} : {}}>
        <Button onClick={this.isNotCustomer}>Neukunde</Button>
        <br/><br/>
        <Form.Group style={{textAlign:'left'}} controlId="formGridCity">
          <Form.Label>Kundennummer*:</Form.Label>
          <Form.Control name="customerNumber" style={{textAlign:'left'}} value={this.props.formData.customerNumber} onChange={this.props.setForm} />
        </Form.Group>
        <div style={{paddingLeft:20}} className="div_text_left">
        <FontAwesomeIcon icon={faInfoCircle} /> Ihre Kundenummer finden Sie auf einer unserer Rechnungen, unserem Angebot oder auf einem unserer Montagescheine. Ihre Kundennummer beginnt mit einem 'K' gefolgt von 5 Ziffern.
        </div><br/><br/><br/>
        <Form.Group style={{textAlign:'left'}} controlId="formBasicEmail">
          <Form.Label>Telefon*:</Form.Label>
          <Form.Control name="tel" style={{textAlign:'left'}} type="phone" placeholder="" value={this.props.formData.tel} onChange={this.props.setForm}/>
        </Form.Group>
        <Form.Group style={{textAlign:'left'}} controlId="formBasicEmail">
          <Form.Label>Email Adresse*:</Form.Label>
          <Form.Control name="email" style={{textAlign:'left'}} type="email" placeholder="" value={this.props.formData.email} onChange={this.props.setForm}/>
        </Form.Group>
        <Form.Group style={{textAlign:'left'}} controlId="exampleForm.ControlTextarea1">
          <Form.Label>Zusatzinformationen</Form.Label>
          <Form.Control name="extraContact" style={{textAlign:'left'}} as="textarea" rows={3} value={this.props.formData.extraContact} onChange={this.props.setForm}/>
          <div className="div_text_left"><p style={{color:'#eb4f34'}}>* Pflichtfeld</p></div><br/>
        </Form.Group>
        </div>

        <Button className="btn_left" onClick={this.props.navigation.previous}>Zurück</Button>
        {renderNextButton()}
        </div>
        </Container>
        <div style={{height:100}}>
        </div>
      </div>



      );
    };
};

export default Contact;
