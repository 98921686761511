import React from "react";

import { Form, Container, Button, ProgressBar } from "react-bootstrap";
import logo from "./logo.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

class Address extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      extra_field: 0,
      extra_field_description: "",
      selectData: [],
    };
  }

  componentDidMount() {
    const apiUrl = `https://rudack.rudack-video.de:3002/work?subject_id=${encodeURIComponent(
      this.props.formData.subject
    )}`;

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => this.init(data));
  }
  init(data) {
    console.log("update data");
    //this.setState({value:''});
    var dataArray = [];
    for (var i = 0; i < data.length; i++) {
      dataArray.push({
        value: data[i].id,
        label: data[i].work_package_titel,
        extra_field: data[i].extra_field,
        extra_field_decription: data[i].extra_field_decription,
        isDisabled: data[i].disabled,
      });
    }
    this.setState({ selectData: dataArray });
    console.log(dataArray);
    this.setState({ data: data });
    console.log(data[0]);
    console.log(this.props.formData.package_extra_description);
    this.setState({ extra_field: this.props.formData.package_extra_field });
    this.setState({
      extra_field_description: this.props.formData.package_extra_description,
    });
    //this.props.formData.package_extra_field = data[0].extra_field;
    //this.props.formData.work_package = (data[0].id).toString();
    console.log(this.props.formData.package_extra_description);
  }
  selectUpdate(e) {
    //this.props.setForm(e);
    this.props.formData.work_package_text = e.label;
    this.props.formData.work_package = e.value.toString();
    this.setState({ value: e.work_package_titel });
    localStorage.removeItem("data");
    this.setState({ extra_field: e.extra_field });
    this.setState({ extra_field_description: e.extra_field_decription });
    this.props.formData.package_extra_field = e.extra_field;
    this.props.formData.package_extra_description = e.extra_field_decription;
  }
  getFiles(files) {
    console.log(JSON.stringify(files));
    this.props.formData.attachments = JSON.stringify(files);
  }

  render() {
    const renderExtraField = () => {
      if (this.state.extra_field === 1) {
        return (
          <Form.Group
            style={{ textAlign: "left" }}
            controlId="exampleForm.ControlTextarea1"
          >
            <Form.Label>{this.state.extra_field_description}*</Form.Label>
            <Form.Control
              name="package_extra"
              style={{ textAlign: "left" }}
              as="textarea"
              rows={3}
              value={this.props.formData.package_extra}
              onChange={this.props.setForm}
            />
            <div className="div_text_left">
              <p style={{ color: "#eb4f34" }}>* Pflichtfeld</p>
            </div>
          </Form.Group>
        );
      }
    };
    const renderNextButton = () => {
      if (
        this.props.formData.work_package !== "" &&
        this.state.extra_field !== 1
      ) {
        return (
          <Button className="btn_right" onClick={this.props.navigation.next}>
            Weiter
          </Button>
        );
      } else if (
        this.props.formData.work_package !== "" &&
        this.state.extra_field === 1 &&
        this.props.formData.package_extra !== ""
      ) {
        return (
          <Button className="btn_right" onClick={this.props.navigation.next}>
            Weiter
          </Button>
        );
      } else {
        return (
          <Button
            className="btn_right"
            onClick={this.props.navigation.next}
            disabled
          >
            Weiter
          </Button>
        );
      }
    };
    return (
      <div className="form">
        <ProgressBar now={25} label="2 von 7" />
        <img
          style={{ marginTop: 40, marginBottom: 40 }}
          src={logo}
          alt="Logo"
        />
        <div style={{ marginTop: 10, marginBottom: 40 }}></div>
        <Container>
          <Form>
            <Select
              isSearchable={false}
              placeholder={this.props.formData.work_package_text}
              value={this.state.value}
              name="work_package"
              options={this.state.selectData}
              onChange={(e) => this.selectUpdate(e)}
            />
            <br />
            {renderExtraField()}
            <div style={{ height: 40 }}></div>
          </Form>
          <FontAwesomeIcon icon={faInfoCircle} /> Nach erfolgreicher Buchung
          Ihres Termins haben Sie die Möglichkeit uns Bilder zu Ihrem Anliegen
          zur Verfügung zu stellen. Nutzen Sie hierzu den Link aus der
          Bestätigungs-Email.
          <br />
          <br />
          <div>
            <Button
              className="btn_left"
              onClick={this.props.navigation.previous}
            >
              Zurück
            </Button>
            {renderNextButton()}
          </div>
        </Container>
      </div>
    );
  }
}
export default Address;
