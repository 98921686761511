import React from "react";
import logo from './logo.png';
import { ProgressBar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { Container} from 'react-bootstrap';

class Submit extends React.Component {
  render(){
    return (
      <div className="form">
      <ProgressBar now={90} label="6 von 7" />
      <img style={{marginTop:40,marginBottom:40}} src={logo} alt="Logo" />
      <div style={{marginTop:10,marginBottom:40}}>
      </div>

      <div>
        <Container>
        <h4>Nur noch ein letzter Schritt.</h4><br/><br/>
        <p>Wir haben Ihnen eine Email an "{this.props.formData.email}" gesendet. In dieser Email finden Sie einen Link mit dem Sie Ihren Termin bestätigen.</p>

        <FontAwesomeIcon icon={faInfoCircle} /> Bitte beachten Sie das Ihr Termin nur an uns Übermittelt wird wenn Sie diesen in der Email bestätigen.
        <br/><br/>
        <p>Sie können diese Seite nun schließen</p>
        <br/><br/><br/>
        <p>Bei Problemen oder Fragen zu Ihrem Termin geben Sie bitte immer die Termin-ID an: <b>{this.props.formData.bookingCode}</b></p>
        </Container>
      </div>
      </div>
    );
  };
};

export default Submit;
