import React from "react";
import {
  Container,
  Form,
  Col,
  Row,
  Button,
  ProgressBar,
  Alert,
} from "react-bootstrap";
import logo from "./logo.png";
import Moment from "moment";
import LoadingOverlay from "react-loading-overlay-ts";
class Review extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      captcha: false,
      loading: false,
      alert: false,
      datenschutzChecked: false,
      infoChecked: false,
    };
    this.datenschutzChange = this.datenschutzChange.bind(this);
    this.infoChange = this.infoChange.bind(this);
  }
  submit() {
    this.setState({ loading: true });
    if (this.props.formData.isCustomer === 1) {
      console.log("submit is customer");
      let dateStr = Moment(this.props.formData.date).format("DD.MM.yyyy");
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          is_customer: this.props.formData.isCustomer,
          customer_number: this.props.formData.customerNumber,
          email: this.props.formData.email,
          tel: this.props.formData.tel,
          subject: this.props.formData.subject,
          work_package: this.props.formData.work_package,
          date: dateStr,
          time: this.props.formData.time,
          extraContact: this.props.formData.extraContact,
          packageExtra: this.props.formData.package_extra,
          packageExtraField: this.props.formData.package_extra_field,
        }),
      };
      fetch("https://rudack.rudack-video.de:3002/book", requestOptions)
        .then((response) => response.json())
        .then((data) => this.checkResponce(data));
    } else {
      console.log("submit is not customer");
      let dateStr = Moment(this.props.formData.date).format("DD.MM.yyyy");
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          is_customer: this.props.formData.isCustomer,
          email: this.props.formData.email,
          tel: this.props.formData.tel,
          first_name: this.props.formData.firstName,
          last_name: this.props.formData.lastName,
          address: this.props.formData.address,
          city: this.props.formData.city,
          zip: this.props.formData.zip,
          subject: this.props.formData.subject,
          work_package: this.props.formData.work_package,
          date: dateStr,
          time: this.props.formData.time,
          extraContact: this.props.formData.extraContact,
          packageExtra: this.props.formData.package_extra,
          packageExtraField: this.props.formData.package_extra_field,
        }),
      };
      fetch("https://rudack.rudack-video.de:3002/book", requestOptions)
        .then((response) => response.json())
        .then((data) => this.checkResponce(data));
    }
  }
  checkResponce(data) {
    console.log(data);
    if (data.success === "true") {
      this.props.formData.bookingCode = data.booking_code;
      this.props.navigation.go("submit");
    } else {
      this.setState({ loading: false });
      this.setState({ alert: true });
    }
  }
  checkCaptcha(result) {
    this.setState({ captcha: result });
  }
  datenschutzChange(evt) {
    this.setState({ datenschutzChecked: evt.target.checked });
  }
  infoChange(evt) {
    this.setState({ infoChecked: evt.target.checked });
  }

  render() {
    //const date = format(new Date(this.props.formData.date), "dd.MM.yyyy");
    const renderReview = () => {
      if (this.props.formData.isCustomer === 1) {
        return (
          <Container>
            <Row>
              <Col xs={5} style={{ textAlign: "right" }}>
                Kundennummer:
              </Col>
              <Col style={{ textAlign: "left" }}>
                {this.props.formData.customerNumber}
              </Col>
            </Row>
            <Row>
              <Col xs={5} style={{ textAlign: "right" }}>
                Email:
              </Col>
              <Col style={{ textAlign: "left" }}>
                {this.props.formData.email}
              </Col>
            </Row>
            <Row>
              <Col xs={5} style={{ textAlign: "right" }}>
                Telefon:
              </Col>
              <Col style={{ textAlign: "left" }}>{this.props.formData.tel}</Col>
            </Row>
            <Row>
              <Col xs={5} style={{ textAlign: "right" }}>
                Termin:
              </Col>
              <Col style={{ textAlign: "left" }}>
                {Moment(this.props.formData.date).format("DD.MM.yyyy")} -{" "}
                {this.props.formData.time} Uhr
              </Col>
            </Row>
          </Container>
        );
      } else {
        return (
          <Container>
            <Row>
              <Col xs={5} style={{ textAlign: "right" }}>
                Name:
              </Col>
              <Col style={{ textAlign: "left" }}>
                {this.props.formData.firstName} {this.props.formData.lastName}
              </Col>
            </Row>
            <Row>
              <Col xs={5} style={{ textAlign: "right" }}>
                Adresse:
              </Col>
              <Col style={{ textAlign: "left" }}>
                {this.props.formData.address}, {this.props.formData.zip}{" "}
                {this.props.formData.city}
              </Col>
            </Row>
            <Row>
              <Col xs={5} style={{ textAlign: "right" }}>
                Email:
              </Col>
              <Col style={{ textAlign: "left" }}>
                {this.props.formData.email}
              </Col>
            </Row>
            <Row>
              <Col xs={5} style={{ textAlign: "right" }}>
                Telefon:
              </Col>
              <Col style={{ textAlign: "left" }}>{this.props.formData.tel}</Col>
            </Row>
            <Row>
              <Col xs={5} style={{ textAlign: "right" }}>
                Termin:
              </Col>
              <Col style={{ textAlign: "left" }}>
                {Moment(this.props.formData.date).format("DD.MM.yyyy")} -{" "}
                {this.props.formData.time} Uhr
              </Col>
            </Row>
          </Container>
        );
      }
    };
    const renderSubmitButton = () => {
      //if (this.state.captcha === true){
      if (
        this.state.datenschutzChecked === true &&
        this.state.infoChecked === true
      ) {
        return (
          <Button onClick={() => this.submit()}>
            Termin verbindlich buchen
          </Button>
        );
      } else {
        return (
          <Button onClick={() => this.submit()} disabled>
            Termin verbindlich buchen
          </Button>
        );
      }
    };
    const renderAlert = () => {
      if (this.state.alert === true) {
        return (
          <Alert key="1" variant="danger">
            Bei der Buchung ihres Termins ist ein fehler aufgetreten, bitte
            versuchen Sie es später erneut.
          </Alert>
        );
      }
    };
    const renderExtraContact = () => {
      if (this.props.formData.extraContact !== "") {
        return (
          <div>
            <div style={{ paddingTop: 20 }}>Zusatzinformation:</div>
            <div style={{ maxWidth: 400, margin: "0 auto" }}>
              {this.props.formData.extraContact}
            </div>
          </div>
        );
      }
    };
    const spinner = this.state.loading;
    return (
      <LoadingOverlay active={spinner} spinner text={"Termin wird gebucht"}>
        <div className="form">
          <ProgressBar now={75} label="5 von 7" />
          {renderAlert()}

          <img
            style={{ marginTop: 40, marginBottom: 40 }}
            src={logo}
            alt="Logo"
          />
          <div style={{ marginTop: 10, marginBottom: 40 }}>
            <h5>Zusammenfassung:</h5>
            <div>
              <br />
              {renderReview()}
              {renderExtraContact()}
              <div style={{ height: 60 }}></div>
              {/*<div className="row">
        <div className="col-centered">
          <Captcha
            onChange={e => this.checkCaptcha(e)}
            placeholder="Zeichen eingeben" // optional
            length={6} // default
          />
        </div>
      </div>
      <div style={{height:20}}></div>*/}
              <Container>
                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check
                    checked={this.state.datenschutzChecked}
                    onChange={this.datenschutzChange}
                    type="checkbox"
                    label="Ich bin mit den folgenden Bedingungen einverstanden*:"
                  />
                </Form.Group>
                <a
                  target="_blank"
                  href="https://clickandmeet.rudack-elektrotechnik.de/datenschutz_click_and_meet.pdf"
                  rel="noreferrer"
                >
                  Datenschutzinformationen
                </a>
                <br />
                <br />
                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check
                    checked={this.state.infoChecked}
                    onChange={this.infoChange}
                    type="checkbox"
                    label="Ich bin mit den folgenden Zusatzinformationen einverstanden*:"
                  />
                </Form.Group>
                <a
                  target="_blank"
                  href="https://clickandmeet.rudack-elektrotechnik.de/zusatzinformationen_click_and_meet.pdf"
                  rel="noreferrer"
                >
                  Zusatzinformationen
                </a>
              </Container>
              <div style={{ height: 20 }}></div>
              <br />
              <p style={{ color: "#eb4f34" }}>* Pflichtfeld</p>
              <Button onClick={this.props.navigation.previous}>Zurück</Button>
              &nbsp;
              {renderSubmitButton()}
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default Review;
