import React from "react";
import logo from "./logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Container } from "react-bootstrap";
import MultipleImageUploadComponent from "./MultipleImageUploadComponent";
import LoadingOverlay from "react-loading-overlay-ts";

class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = {
      loading: false,
      statusText: "",
      statusColor: "#000000",
      checkId: "",
      booking_id: "",
      done: false,
    };
  }
  componentDidMount() {
    const url = new URL(window.location.href);
    const booking_id = url.searchParams.get("booking_id");

    const apiUrl = `https://rudack.rudack-video.de:3002/verify_booking_code?booking_id=${encodeURIComponent(
      booking_id
    )}&api_key=F1BFE9454311CC3A4D3335EF52435`;
    console.log(booking_id);
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => this.checkId(data));
  }
  checkId(data) {
    if (data.success === "true") {
      this.setState({ checkId: true });
      const url = new URL(window.location.href);
      const booking_id = url.searchParams.get("booking_id");
      this.setState({ booking_id: booking_id });
    } else {
      this.setState({ checkId: false });
    }
  }
  onChange(e) {
    if (e === "error") {
      this.setState({ loading: false });
      this.setState({ statusText: "Fehler beim hochladen" });
      this.setState({ statusColor: "#ff0000" });
    } else {
      this.setState({ loading: e });
      if (e === false) {
        this.setState({ statusText: "Datein erfolgreich hochgeladen" });
        this.setState({ statusColor: "#1e7e34" });
        this.setState({ done: true });
      }
      this.forceUpdate();
    }
  }

  render() {
    const spinner = this.state.loading;
    const status = this.state.statusText;
    const statusColor = this.state.statusColor;
    const checkId = this.state.checkId;
    const booking_id = this.state.booking_id;
    const done = this.state.done;
    console.log(statusColor);

    return (
      <LoadingOverlay
        active={spinner}
        spinner
        text={"Datein werden hochgeladen..."}
      >
        <div className="form">
          <img
            style={{ marginTop: 40, marginBottom: 40 }}
            src={logo}
            alt="Logo"
          />
          <div style={{ marginTop: 10, marginBottom: 40 }}></div>

          <div>
            <Container>
              {checkId ? (
                <div>
                  <p>
                    Übermittel Sie uns Fotos zu Ihrerm Termin damit wir uns
                    besser auf die Arbeiten vorbereiten können
                  </p>
                  <p>
                    Buchungs-ID:{" "}
                    <span style={{ color: "#1e7e34" }}>
                      {booking_id} <FontAwesomeIcon icon={faCheck} />
                    </span>
                  </p>
                  <h4 style={{ color: statusColor }}>{status}</h4>
                  {!done ? (
                    <MultipleImageUploadComponent
                      onChange={this.onChange}
                      booking_id={this.state.booking_id}
                    />
                  ) : null}
                </div>
              ) : (
                <p style={{ color: "#ff0000" }}>
                  Der Link ist ungültig, bitte kontaktieren Sie uns telefonisch
                  oder per Email.
                </p>
              )}
            </Container>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default FileUpload;
