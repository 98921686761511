import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import "./styles.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import MultiStepForm from "./MultiStepForm";
import Finished from "./Finished";
import FileUpload from "./Fileupload";

function App() {
  
  return (

    <div className="app">
      <div className="wrapper">
        <BrowserRouter>
          <Switch>
           <Route path="/" component={MultiStepForm} exact/>
           <Route path="/finished" component={Finished}/>
           <Route path="/fileupload" component={FileUpload}/>
          <Route component={Error}/>
         </Switch>
         </BrowserRouter>
      </div>
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
