import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import de from "date-fns/locale/de"; // the locale you want
import logo from "./logo.png";
import { addDays, addMinutes } from "date-fns";
import { format } from "date-fns";
import LoadingOverlay from "react-loading-overlay-ts";
import {
  Form,
  Container,
  Col,
  Row,
  Button,
  ProgressBar,
} from "react-bootstrap";
import { isMobile } from "react-device-detect";

class Meeting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      date: "",
      dis8: 0,
      dis9: 0,
      dis10: 0,
      dis11: 0,
      dis12: 0,
      dis13: 0,
      dis14: 0,
      dis15: 0,
      loading: true,
      loadingText: "Ein kleiner Moment zum Entspannen...",
      holiday: [],
      firstLoad: 1,
    };
  }

  isWeekday = (date: Date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6 && day !== 1 && day !== 5;
  };

  handleChange(date) {
    console.log(date);
    this.setState({ date: format(date, "dd.MM.yyyy") });
    this.filterList(format(date, "dd.MM.yyyy"));
    this.props.formData.date = date;
  }
  filterList(date) {
    let selectValue;
    let firstValueSet = 0;
    console.log("FILTER LIST");
    this.setState({ dis8: 1 });
    this.setState({ dis9: 1 });
    this.setState({ dis10: 1 });
    this.setState({ dis11: 1 });
    this.setState({ dis12: 1 });
    this.setState({ dis13: 1 });
    this.setState({ dis14: 1 });
    this.setState({ dis15: 1 });
    let tt8 = 0;
    let tt9 = 0;
    let tt10 = 0;
    let tt11 = 0;
    let tt12 = 0;
    let tt13 = 0;
    let tt14 = 0;
    let tt15 = 0;
    //filter available nums
    let data = this.state.data;
    const arr0 = data.filter((d) => d.date === date);
    for (var i = 0; i < this.state.calCount; i++) {
      console.log("round: " + i);
      // eslint-disable-next-line
      const arr1 = arr0.filter((d) => d.num === i);
      console.log(arr1);
      let t8 = 1;
      let t9 = 1;
      let t10 = 1;
      let t11 = 1;
      let t12 = 1;
      let t13 = 1;
      let t14 = 1;
      let t15 = 1;

      for (var x = 0; x < arr1.length; x++) {
        let lines = arr1[x].start.split(":");
        let start = parseInt(lines[0]);
        let lines2 = arr1[x].end.split(":");
        let end = parseInt(lines2[0]);
        if (8 < start || 8 > end) {
        } else {
          t8 = 0;
        }
        if (9 < start || 9 > end) {
        } else {
          t9 = 0;
        }
        if (10 < start || 10 > end) {
        } else {
          t10 = 0;
        }
        if (11 < start || 11 > end) {
        } else {
          t11 = 0;
        }
        if (12 < start || 12 > end) {
        } else {
          t12 = 0;
        }
        if (13 < start || 13 > end) {
        } else {
          t13 = 0;
        }
        if (14 < start || 14 > end) {
        } else {
          t14 = 0;
        }
        if (15 < start || 15 > end) {
        } else {
          t15 = 0;
        }
      }
      if (t8 === 1) {
        tt8 = 1;
      }
      if (t9 === 1) {
        tt9 = 1;
      }
      if (t10 === 1) {
        tt10 = 1;
      }
      if (t11 === 1) {
        tt11 = 1;
      }
      if (t12 === 1) {
        tt12 = 1;
      }
      if (t13 === 1) {
        tt13 = 1;
      }
      if (t14 === 1) {
        tt14 = 1;
      }
      if (t15 === 1) {
        tt15 = 1;
      }
      console.log(
        t8 +
          " " +
          t9 +
          " " +
          t10 +
          " " +
          t11 +
          " " +
          t12 +
          " " +
          t13 +
          " " +
          t14 +
          " " +
          t15
      );
    }
    if (tt8 === 0) {
      this.setState({ dis8: 0 });
    }
    if (tt9 === 0) {
      this.setState({ dis9: 0 });
    }
    if (tt10 === 0) {
      this.setState({ dis10: 0 });
    }
    if (tt11 === 0) {
      this.setState({ dis11: 0 });
    }
    if (tt12 === 0) {
      this.setState({ dis12: 0 });
    }
    if (tt13 === 0) {
      this.setState({ dis13: 0 });
    }
    if (tt14 === 0) {
      this.setState({ dis14: 0 });
    }
    if (tt15 === 0) {
      console.log("endable 15");
      this.setState({ dis15: 0 });
    }
    console.log(
      tt8 +
        " " +
        tt9 +
        " " +
        tt10 +
        " " +
        tt11 +
        " " +
        tt12 +
        " " +
        tt13 +
        " " +
        tt14 +
        " " +
        tt15
    );
    if (tt8 === 1) {
      if (firstValueSet === 0) {
        selectValue = "08:00";
        firstValueSet = 1;
      }
    } else if (tt9 === 1) {
      if (firstValueSet === 0) {
        selectValue = "09:00";
        firstValueSet = 1;
      }
    } else if (tt10 === 1) {
      if (firstValueSet === 0) {
        selectValue = "10:00";
        firstValueSet = 1;
      }
    } else if (tt11 === 1) {
      if (firstValueSet === 0) {
        selectValue = "11:00";
        firstValueSet = 1;
      }
    } else if (tt12 === 1) {
      if (firstValueSet === 0) {
        selectValue = "12:00";
        firstValueSet = 1;
      }
    } else if (tt13 === 1) {
      if (firstValueSet === 0) {
        selectValue = "13:00";
        firstValueSet = 1;
      }
    } else if (tt14 === 1) {
      if (firstValueSet === 0) {
        selectValue = "14:00";
        firstValueSet = 1;
      }
    } else if (tt15 === 1) {
      if (firstValueSet === 0) {
        selectValue = "15:00";
        firstValueSet = 1;
      }
    }
    console.log("SELECT VALUE");
    console.log(selectValue);
    this.props.formData.time = selectValue;
  }

  componentDidMount() {
    this.setState({ date: this.props.formData.date });
    console.log("DATE:");
    console.log(this.props.formData.date);
    //this.setState({data:localStorage.getItem('data')});
    console.log(localStorage.getItem("data"));
    let dataDate = new Date(localStorage.getItem("data_date"));
    let todayPlusOne = addMinutes(new Date(), 15);
    if (
      localStorage.getItem("data") === null ||
      dataDate >= todayPlusOne ||
      localStorage.getItem("data_date") === null
    ) {
      localStorage.setItem("data_date", new Date());
      setTimeout(
        function () {
          //Start the timer
          this.setState({ loadingText: "Freie Termine werden geladen..." }); //After 1 second, set render to true
        }.bind(this),
        10000
      );
      const apiUrl = `https://rudack.rudack-video.de:3002/dates?start=${encodeURIComponent(
        format(addDays(new Date(), 7), "dd.MM.yyyy")
      )}&end=${encodeURIComponent(
        format(addDays(new Date(), 50), "dd.MM.yyyy")
      )}&sid=${encodeURIComponent(this.props.formData.subject)}`;
      console.log(apiUrl);
      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => this.calcTimes(data[1], data[0].count));
    } else {
      this.calcTimes(
        JSON.parse(localStorage.getItem("data")),
        localStorage.getItem("count")
      );
      //this.filterList(this.props.formData.date);
    }
  }

  calcTimes(data, count) {
    console.log(data);
    this.setState({ data: data });
    this.setState({ calCount: count });
    localStorage.setItem("data", JSON.stringify(data));
    localStorage.setItem("count", count);
    const arr2 = data.filter((d) => d.date === "10.05.2021");
    console.log(arr2);
    this.calcDays(data);
    this.setState({ loading: false });
  }
  async calcDays(data) {
    let x = 0;
    for (x = 7; x < 50; x++) {
      let thisDate = addDays(new Date(), x);
      const arr1 = data.filter(
        (d) => d.date === format(thisDate, "dd.MM.yyyy")
      );
      let tt8 = 0;
      let tt9 = 0;
      let tt10 = 0;
      let tt11 = 0;
      let tt12 = 0;
      let tt13 = 0;
      let tt14 = 0;
      let tt15 = 0;
      for (var i = 0; i < this.state.calCount; i++) {
        console.log("round: " + i);
        // eslint-disable-next-line
        const arr2 = arr1.filter((d) => d.num === i);
        console.log(arr2);
        let t8 = 1;
        let t9 = 1;
        let t10 = 1;
        let t11 = 1;
        let t12 = 1;
        let t13 = 1;
        let t14 = 1;
        let t15 = 1;

        for (var y = 0; y < arr2.length; y++) {
          let lines = arr2[y].start.split(":");
          let start = parseInt(lines[0]);
          let lines2 = arr2[y].end.split(":");
          let end = parseInt(lines2[0]);
          if (8 < start || 8 > end) {
          } else {
            t8 = 0;
          }
          if (9 < start || 9 > end) {
          } else {
            t9 = 0;
          }
          if (10 < start || 10 > end) {
          } else {
            t10 = 0;
          }
          if (11 < start || 11 > end) {
          } else {
            t11 = 0;
          }
          if (12 < start || 12 > end) {
          } else {
            t12 = 0;
          }
          if (13 < start || 13 > end) {
          } else {
            t13 = 0;
          }
          if (14 < start || 14 > end) {
          } else {
            t14 = 0;
          }
          if (15 < start || 15 > end) {
          } else {
            t15 = 0;
          }
        }
        if (t8 === 1) {
          tt8 = 1;
        }
        if (t9 === 1) {
          tt9 = 1;
        }
        if (t10 === 1) {
          tt10 = 1;
        }
        if (t11 === 1) {
          tt11 = 1;
        }
        if (t12 === 1) {
          tt12 = 1;
        }
        if (t13 === 1) {
          tt13 = 1;
        }
        if (t14 === 1) {
          tt14 = 1;
        }
        if (t15 === 1) {
          tt15 = 1;
        }
        console.log(
          t8 +
            " " +
            t9 +
            " " +
            t10 +
            " " +
            t11 +
            " " +
            t12 +
            " " +
            t13 +
            " " +
            t14 +
            " " +
            t15
        );
      }

      console.log(format(thisDate, "dd.MM.yyyy"));
      if (tt8 + tt9 + tt10 + tt11 + tt12 + tt13 + tt14 + tt15 === 0) {
        console.log("hide this date:");
        console.log(format(thisDate, "dd.MM.yyyy"));
        await this.setState({
          holiday: this.state.holiday.concat(new Date(thisDate)),
        });
      }
    }

    console.log(this.state.holiday);
  }
  contains(array, key, val) {
    for (let i = 0; i < array.length; i++) {
      if (array[i][key] === val) return true;
    }
    return false;
  }
  render() {
    const dis8 = this.state.dis8;
    const dis9 = this.state.dis9;
    const dis10 = this.state.dis10;
    const dis11 = this.state.dis11;
    const dis12 = this.state.dis12;
    const dis13 = this.state.dis13;
    const dis14 = this.state.dis14;
    const dis15 = this.state.dis15;
    const spinner = this.state.loading;
    const loadingText = this.state.loadingText;

    const renderNextButton = () => {
      if (this.props.formData.date !== "" && this.props.formData.time !== "") {
        return (
          <Button className="btn_right" onClick={this.props.navigation.next}>
            Weiter
          </Button>
        );
      } else {
        return (
          <Button
            className="btn_right"
            onClick={this.props.navigation.next}
            disabled
          >
            Weiter
          </Button>
        );
      }
    };
    let colwidth = 6;
    let selectH: 270;
    let selectC = 6;
    if (isMobile) {
      colwidth = 12;
      selectH = 40;
      selectC = 1;
    } else {
      colwidth = 6;
      selectH = 270;
      selectC = 6;
    }
    const holidays = this.state.holiday;

    return (
      <LoadingOverlay active={spinner} spinner text={loadingText}>
        <div className="form">
          <ProgressBar now={45} label="3 von 7" />

          <img
            style={{ marginTop: 40, marginBottom: 40 }}
            src={logo}
            alt="Logo"
          />
          <div style={{ marginTop: 10, marginBottom: 40 }}></div>
          <Container>
            <Row>
              <Col xs={colwidth}>
                <p>Datum:</p>
                <DatePicker
                  onSelect={(e) => this.handleChange(e)}
                  inline
                  locale={de}
                  filterDate={this.isWeekday}
                  minDate={addDays(new Date(), 7)}
                  maxDate={addDays(new Date(), 50)}
                  excludeDates={holidays}
                />
              </Col>

              <Col>
                <p>Uhrzeit:</p>
                <Form>
                  <Form.Group>
                    <Form.Control
                      style={{ height: selectH }}
                      name="time"
                      as="select"
                      htmlSize={selectC}
                      value={this.props.formData.time}
                      onChange={this.props.setForm}
                    >
                      {!dis8 ? (
                        <option value="08:00" disabled>
                          08:00
                        </option>
                      ) : (
                        <option style={{ color: "#11DB00" }} value="08:00">
                          08:00
                        </option>
                      )}
                      {!dis9 ? (
                        <option value="09:00" disabled>
                          09:00
                        </option>
                      ) : (
                        <option style={{ color: "#11DB00" }} value="09:00">
                          09:00
                        </option>
                      )}
                      {!dis10 ? (
                        <option value="10:00" disabled>
                          10:00
                        </option>
                      ) : (
                        <option style={{ color: "#11DB00" }} value="10:00">
                          10:00
                        </option>
                      )}
                      {!dis11 ? (
                        <option value="11:00" disabled>
                          11:00
                        </option>
                      ) : (
                        <option style={{ color: "#11DB00" }} value="11:00">
                          11:00
                        </option>
                      )}
                      {!dis12 ? (
                        <option value="12:00" disabled>
                          12:00
                        </option>
                      ) : (
                        <option style={{ color: "#11DB00" }} value="12:00">
                          12:00
                        </option>
                      )}
                      {!dis13 ? (
                        <option value="13:00" disabled>
                          13:00
                        </option>
                      ) : (
                        <option style={{ color: "#11DB00" }} value="13:00">
                          13:00
                        </option>
                      )}
                      {!dis14 ? (
                        <option value="14:00" disabled>
                          14:00
                        </option>
                      ) : (
                        <option style={{ color: "#11DB00" }} value="14:00">
                          14:00
                        </option>
                      )}
                      {!dis15 ? (
                        <option value="15:00" disabled>
                          15:00
                        </option>
                      ) : (
                        <option style={{ color: "#11DB00" }} value="15:00">
                          15:00
                        </option>
                      )}
                    </Form.Control>
                  </Form.Group>
                </Form>
              </Col>
            </Row>

            <div>
              <Button
                className="btn_left"
                onClick={this.props.navigation.previous}
              >
                Zurück
              </Button>
              {renderNextButton()}
            </div>
          </Container>
          <div style={{ height: 100 }}></div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default Meeting;
