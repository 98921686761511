import React from "react";
import { Form, Container, Button, ProgressBar } from "react-bootstrap";
import logo from "./logo.png";
import LoadingOverlay from "react-loading-overlay-ts";

class Names extends React.Component {
  // const Names = ({ setForm, formData, navigation }) => {
  //   const { firstName, lastName, nickName, subject } = formData;
  //
  //   const { next } = navigation;
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      error: true,
    };
  }
  componentDidMount() {
    const apiUrl = "https://rudack.rudack-video.de:3002/subjects";
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => this.setState({ data: data }));
  }
  selectUpdate(e) {
    this.props.setForm(e);
    console.log("selection changed");
    this.props.formData.work_package = "";
    this.props.formData.work_package_text = "Leistung auswählen";
    this.props.formData.package_extra_field = 0;
    localStorage.removeItem("data");
  }
  render() {
    document.getElementsByTagName("META")[0].content =
      "width=device-width, initial-scale=1.0, shrink-to-fit=no, user-scalable=no";

    return (
      <LoadingOverlay
        active={true}
        spinner={false}
        text={
          "Momentan sind leider alle Termin vergriffen, versuchen Sie es später erneut!"
        }
      >
        <div className="form">
          <ProgressBar now={10} label="1 von 7" />
          <img
            style={{ marginTop: 40, marginBottom: 40 }}
            src={logo}
            alt="Logo"
          />
          <div style={{ marginTop: 10, marginBottom: 40 }}>
            <h5>
              Buchen Sie Ihren Termin mit unseren Servicetechnikern direkt
              Online!
            </h5>
            <a
              target="_blank"
              href="https://clickandmeet.rudack-elektrotechnik.de/datenschutz_click_and_meet.pdf"
              rel="noreferrer"
            >
              Datenschutzerklärung
            </a>
            <br />
            <a
              target="_blank"
              href="https://clickandmeet.rudack-elektrotechnik.de/zusatzinformationen_click_and_meet.pdf"
              rel="noreferrer"
            >
              Zusatzinformationen
            </a>
          </div>

          <Container>
            <Form>
              <Form.Group controlId="exampleForm.SelectCustomHtmlSize">
                <Form.Label>
                  Wählen Sie Ihren gewünschten Leistungsbereich
                </Form.Label>
                <Form.Control
                  name="subject"
                  as="select"
                  htmlSize={3}
                  value={this.props.formData.subject}
                  onChange={(e) => this.selectUpdate(e)}
                >
                  {this.state.data.map((items) => (
                    <option key={items.id} value={items.id}>
                      {items.subject_title}
                    </option>
                  ))}
                  <optgroup label=""></optgroup>
                </Form.Control>
              </Form.Group>
            </Form>

            <div>
              <Button
                className="btn_right"
                onClick={this.props.navigation.next}
              >
                Weiter
              </Button>
            </div>
          </Container>
        </div>
      </LoadingOverlay>
    );
  }
}

export default Names;
