import React from "react";
import { useForm, useStep } from "react-hooks-helper";
import { addDays } from 'date-fns';

import Names from "./Names";
import Address from "./Address";
import Meeting from "./Meeting";
import Contact from "./Contact";
import Review from "./Review";
import Submit from "./Submit";

import "./styles.css";

const steps = [
  { id: "names" },
  { id: "address" },
  { id: "meeting" },
  { id: "contact" },
  { id: "review" },
  { id: "submit" }
];

const defaultData = {
  firstName: "",
  lastName: "",
  subject:"1",
  subject_text:"",
  work_package:"",
  work_package_text:"Leistung auswählen",
  test:"test",
  address: "",
  city: "",
  zip: "",
  email: "",
  tel: "",
  date:(addDays(new Date(), 7)),
  time:'',
  customerNumber:'K',
  isCustomer:0,
  extraContact:'',
  package_extra_field:0,
  package_extra:'',
  package_extra_description:'',
  bookingCode:'',
  attachments:[],
};

const MultiStepForm = ({ images }) => {
  const [formData, setForm] = useForm(defaultData);
  const { step, navigation } = useStep({ initialStep: 0, steps });
  const { id } = step;

  const props = { formData, setForm, navigation };

  switch (id) {
    case "names":
      return <Names {...props} />;
    case "address":
      return <Address {...props} />;
    case "meeting":
      return <Meeting {...props} />;
    case "contact":
      return <Contact {...props} />;
    case "review":
      return <Review {...props} />;
    case "submit":
      return <Submit {...props} />;
    default:
      return null;
  }
};

export default MultiStepForm;
