import { React, Component } from "react";
import { Form } from "react-bootstrap";
import axios from "axios";
import disableScroll from "disable-scroll";

export default class MultipleImageUploadComponent extends Component {
  fileObj = [];
  fileArray = [];

  constructor(props) {
    super(props);
    this.state = {
      file: [null],
      files: [],
    };
    this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
  }

  uploadMultipleFiles(e) {
    this.fileObj = [];
    this.fileObj.push(e.target.files);
    for (let i = 0; i < this.fileObj[0].length; i++) {
      this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]));
      this.setState((prevState) => ({
        files: [...prevState.files, this.fileObj[0][i]],
      }));
    }
    this.setState({ file: this.fileArray });
    console.log(this.fileArray);
    console.log(this.state.files);
  }

  uploadFiles(e) {
    window.scrollTo(0, 0);
    disableScroll.on();
    this.props.onChange(true);
    e.preventDefault();
    var uploadArray = [];
    for (let i = 0; i < this.state.files.length; i++) {
      uploadArray.push(this.state.files[i]);
    }
    const data = new FormData();
    // eslint-disable-next-line
    uploadArray.map((file) => {
      data.append(`uploadedFile`, file);
    });
    data.append("api_key", "F1BFE9454311CC3A4D3335EF52435");
    data.append("booking_code", this.props.booking_id);
    if (uploadArray.length === 1) {
      axios({
        method: "post",
        url: "https://rudack.rudack-video.de:3002/uploadfile",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((res) => {
        // then print response status

        console.log(res.statusText);
        console.log(res);
        disableScroll.off();
        if (res.data.message === "File is uploaded") {
          this.props.onChange(false);
        } else {
          this.props.onChange("error");
        }
      });
    } else {
      axios({
        method: "post",
        url: "https://rudack.rudack-video.de:3002/uploadfiles",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((res) => {
        // then print response status

        console.log(res.statusText);
        console.log(res);
        if (res.data.status === true) {
          this.props.onChange(false);
        } else {
          this.props.onChange("error");
        }
      });
    }
  }

  render() {
    return (
      <form>
        <div className="form-group multi-preview"></div>

        <Form>
          <Form.Group>
            <Form.File
              className="hiddenText"
              onChange={this.uploadMultipleFiles}
              id="exampleFormControlFile1"
              label=""
              multiple
            />
          </Form.Group>
        </Form>

        {this.state.file.map((img, index) => (
          <img style={{ width: 300 }} src={img} alt="bild" />
        ))}
        <br />
        <br />
        <button
          type="button"
          className="btn btn-success"
          onClick={this.uploadFiles}
        >
          Hochladen
        </button>
        <br />
        <br />
      </form>
    );
  }
}
