import React from "react";
import logo from './logo.png';
import { ProgressBar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { Container} from 'react-bootstrap';

class Finished extends React.Component {
  render(){
    return (
      <div className="form">
      <ProgressBar now={100} label="7 von 7" />
      <img style={{marginTop:40,marginBottom:40}} src={logo} alt="Logo" />
      <div style={{marginTop:10,marginBottom:40}}>
      </div>

      <div>
        <Container>
        <h4>Ihr Termin wurde erfolgreich gebucht.</h4><br/><br/>
        <FontAwesomeIcon icon={faInfoCircle} /> Sie erhalten zusätzlich eine Bestätigung des Termins per Email.
        </Container>
        <br/><br/>
      </div>
      </div>
    );
  };
};

export default Finished;
